// import ApiService from '@/core/services/api.service';
import { addSeconds, getTime } from "date-fns";

// action types

// mutation types
export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";

const state = {
  errors: [],
  messages: [],
};

const getters = {
  errors(state) {
    return state.errors;
  },
  messages(state) {
    return state.messages;
  },
};

const actions = {};

const mutations = {
  [SET_ERROR](state, error) {
    const date = new Date();
    if (error && error.length) {
      error.map((row) => {
        return (row.expiry = getTime(addSeconds(date, 5)));
      });
      state.errors = error;
    } else {
      state.errors = [];
    }
  },
  [SET_MESSAGE](state, message) {
    const date = new Date();
    if (message && message.length) {
      message.map((row) => {
        return (row.expiry = getTime(addSeconds(date, 2)));
      });
      state.messages = message;
    } else {
      state.messages = [];
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
